
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Geist';
  src: url('../public/fonts/Geist-Regular.woff2') format('woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'Geist';
  src: url('../public/fonts/Geist-Medium.woff2') format('woff2');
  font-weight: 500;
}
@font-face {
  font-family: 'Geist';
  src: url('../public/fonts/Geist-SemiBold.woff2') format('woff2');
  font-weight: 600;
}
@font-face {
  font-family: 'Geist Mono';
  src: url('../public/fonts/GeistMono-Regular.woff2') format('woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'Geist Mono';
  src: url('../public/fonts/GeistMono-Medium.woff2') format('woff2');
  font-weight: 500;
}
body {
  margin: 0;
  font-family: 'Geist', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-size: 42px;
  line-height: 48px;
  font-weight: 500;
  letter-spacing: -5%;
}
h2 {
  font-size: 28px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: -5%;
}
h3 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: -5%;
}
h4 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}
p {
  font-size: 14px;
  line-height: 20px;
}
h1, h2, h3 {
  margin-top: 40px;
}
h1, h2, h3, ul, p {
  margin-bottom: 20px;
}

li {
  margin-bottom: 12px;
}

ul {
  padding-left: 20px;
}
p, ul, code, h4 {
  color: #3a3a3a;
}
.text-wrapper {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 20px;
}

code {
  font-family: 'Geist Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    font-size: 14px;
    line-height: 20px;
}

.video-container {
  position: relative;
  overflow: hidden;
}

video {
  width: 100%;
  height: auto;
  border-radius: 12px; /* Rounded corners for a polished look */
}
body {
  background-color: #fff;
}